import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[dashboardConnectForm]',
})
export class ConnectFormDirective {
  @Input('connectForm')
  set data(value: any) {
    if (value) {
      const clonedValue = Object.assign({}, value);
      if (clonedValue.hasOwnProperty('translations')) {
        delete clonedValue.translations;
      }

      this.formGroupDirective.form.patchValue(clonedValue);
      this.formGroupDirective.form.markAsPristine();
    }
  }

  constructor(private formGroupDirective: FormGroupDirective) {}
}
