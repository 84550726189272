<div class="dashboard-material-theme dashboard-pwd-reset" fxLayout="column" fxLayoutAlign="start center">
  <mat-card class="dashboard-pwd-reset__card">
    <mat-card-header>
      <mat-card-title id="pwd-reset-title">{{ 'The Incredible Intervention Machine' | uppercase }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        [formGroup]="pwdResetForm"
        fxLayout="column"
        fxLayoutAlign="space-around stretch"
        class="dashboard-pwd-reset__card-form"
      >
        <section>
          <div class="dashboard-pwd-reset__card-form-password-prompt">Please enter your new password to continue.</div>
          <section fxLayout="column" fxLayoutGap="20px">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                id="pwd-reset-input-password"
                matInput
                type="password"
                formControlName="password"
                placeholder="Password"
              />
              <mat-error *ngIf="password.hasError('required')"> Password is <strong>required</strong> </mat-error>
              <mat-error *ngIf="password.hasError('minlength')">
                Password needs to have
                <strong>at least {{ password.errors?.minlength.requiredLength }}</strong>
                characters
              </mat-error>
              <mat-error *ngIf="password.hasError('maxlength')">
                Password needs to have
                <strong>at most {{ password.errors?.maxlength.requiredLength }}</strong>
                characters
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Repeat Password</mat-label>
              <input
                id="pwd-reset-repeat-password"
                type="password"
                matInput
                formControlName="repeatPassword"
                placeholder="Repeat Password"
              />
              <mat-error *ngIf="repeatPassword.hasError('required')">
                Repeat Password is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="repeatPassword.hasError('minlength')">
                Repeat Password needs to have
                <strong>at least {{ repeatPassword.errors?.minlength.requiredLength }}</strong>
                characters
              </mat-error>
              <mat-error *ngIf="repeatPassword.hasError('maxlength')">
                Repeat Password needs to have
                <strong>at most {{ repeatPassword.errors?.maxlength.requiredLength }}</strong>
                characters
              </mat-error>
            </mat-form-field>
          </section>
        </section>
        <p
          class="dashboard-pwd-reset__card-form--error"
          *ngIf="(pwdResetForm.dirty || pwdResetForm.touched) && pwdResetForm.hasError('noMatch')"
        >
          You haven't entered the same password
        </p>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-evenly center">
      <button mat-raised-button color="accent" id="pwd-reset-btn-reset" (click)="pwdReset()">
        {{ 'Reset password' | uppercase }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
