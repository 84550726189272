import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { select, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AngularMaterialModule } from './material/angular-material.module';
import { LoginComponent } from './guest/login/login.component';
import { RegisterComponent } from './guest/register/register.component';
import { CoreModule } from './core/core.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppState, effects, metaReducers, reducers } from './state';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TokenErrorInterceptor } from './core/token-error-interceptor';
import { TokenInterceptor } from './core/token-interceptor';
import { CustomSerializer } from './core/custom-route-serializer';
import { ReactiveComponentModule } from '@ngrx/component';
import { RegisterInfoComponent } from './guest/register/register-info/register-info.component';
import { RegisterFailComponent } from './guest/register/register-fail/register-fail.component';
import { PwdResetComponent } from './guest/pwd-reset/pwd-reset.component';
import { selectIsInitialized } from './state/user/user.selectors';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { QRCodeModule } from 'angularx-qrcode';
import { EventPlannerDialogComponent } from './dashboard/studies/components/event-planner-dialog/event-planner-dialog.component';
import { CanEditTimingRulesDialogComponent } from './dashboard/studies/components/can-edit-timing-rules-dialog/can-edit-timing-rules-dialog.component';

export const DASHBOARD_MQTT_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.host,
  port: environment.mqtt.port,
  username: environment.mqtt.credentials.username,
  password: environment.mqtt.credentials.password,
  protocol: 'wss',
  path: '/mqtt',
  connectOnCreate: false,
};

@NgModule({
  declarations: [AppComponent, LoginComponent, RegisterComponent, RegisterInfoComponent, RegisterFailComponent, PwdResetComponent, EventPlannerDialogComponent, CanEditTimingRulesDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    AngularMaterialModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MqttModule.forRoot(DASHBOARD_MQTT_OPTIONS),
    ReactiveComponentModule,
    EditorModule,
    QRCodeModule,
    FormsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initUser,
      deps: [Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenErrorInterceptor,
      multi: true,
    },
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'js/js.min.js'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function initUser(store: Store<AppState>) {
  return () =>
    new Promise((resolve, reject) => {
      store.pipe(select(selectIsInitialized)).subscribe((res) => {
        if (res) {
          resolve(true);
        }
      });
    });
}
