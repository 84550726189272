import { environment } from 'src/environments/environment';

export const TOKEN_KEY = 'token';
export const SSO_KEY = 'sso';
const EXPIRITYDATE = null;

export function getToken(): string {
  return localStorage.getItem(TOKEN_KEY);
}

export function setToken(token: string): void {
  if (token) {
    if (token.includes('Bearer')) {
      localStorage.setItem(TOKEN_KEY, token);
    } else if (token.includes('bearer')) {
      localStorage.setItem(TOKEN_KEY, token.replace('bearer ', 'Bearer '));
    } else {
      localStorage.setItem(TOKEN_KEY, 'Bearer ' + token)
    }
    setExpirityDate();
  }
}

export function clearToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function tokenIsExpired() {
  if(getToken()) {
    var d = new Date().toString();
    return Date.parse(d) > getExpirityDate();
  }
  else return false;
}

export function getExpirityDate() {
  return Date.parse(localStorage.getItem(EXPIRITYDATE));
}

export function setExpirityDate() {
  var d = new Date();
  d.setSeconds(d.getSeconds() + environment.tokenExpirityTime);
  localStorage.setItem(EXPIRITYDATE, d.toString());
}

export function isSSOLogin(): boolean {
  return localStorage.getItem(SSO_KEY) === '1';
}

export function setSSOLogin(isSSO: boolean): void {
  localStorage.setItem(SSO_KEY, (isSSO ? 1 : 0).toString());
}

export function clearSSO() {
  localStorage.removeItem(SSO_KEY);
}
