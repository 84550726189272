import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-can-edit-timing-rules-dialog',
  templateUrl: './can-edit-timing-rules-dialog.component.html',
  styleUrls: ['./can-edit-timing-rules-dialog.component.scss']
})
export class CanEditTimingRulesDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
