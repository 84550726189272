<div class="dashboard-register-info">
  <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
    <h1 class="dashboard-register-info__title">
      {{ 'Thank you for signing up!' | uppercase }}
    </h1>
    <section class="dashboard-register-info__description">
      This form helps create your profile as a researcher of the BMS Lab Dashboard. There is one step left, on the
      upcoming days you will receive your approved once we have verify your project is registered and has ethical
      approval with in the BMS Lab registration system. (<a
        href="https://bmslab.utwente.nl/project-sign-up/"
        target="_blank"
        >https://bmslab.utwente.nl/project-sign-up</a
      >).
    </section>
    <section class="dashboard-register-info__description">
      Your request which will be processed on Tuesdays and/or Thursdays, you will receive your approval within 5
      workdays.
    </section>
    <section class="dashboard-register-info__description">
      If you have any issue or question do not hesitate on contacting us at:
    </section>
    <section class="dashboard-register-info__description">
      <strong>THE BMS LAB</strong><br />
      University of Twente<br />
      Faculty of Behavioural, Management and Social Sciences (BMS)<br />
      Cubicus B204<br />
      Phone: 053-4892524<br />
      E-mail address: dashboard-bms@utwente.nl
    </section>
    <button mat-raised-button color="accent" id="register-info-btn-back-to-login" fxFlexAlign="end" (click)="back()">
      {{ 'Back to login page' | uppercase }}
    </button>
  </div>
</div>
