import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

import { SANITIZER_TYPES } from '../../dashboard-constants';

@Pipe({
  name: 'dashboardSafe',
})
export class DashboardSafePipe implements PipeTransform {
  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SANITIZER_TYPES.HTML:
        return this.domSanitizer.bypassSecurityTrustHtml(value);
      case SANITIZER_TYPES.STYLE:
        return this.domSanitizer.bypassSecurityTrustStyle(value);
      case SANITIZER_TYPES.SCRIPT:
        return this.domSanitizer.bypassSecurityTrustScript(value);
      case SANITIZER_TYPES.URL:
        return this.domSanitizer.bypassSecurityTrustUrl(value);
      case SANITIZER_TYPES.RESOURCE_URL:
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

  constructor(protected domSanitizer: DomSanitizer) {}
}
