import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface ApiRequestOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private API_URL = environment.apiUrl;
  private PUBLIC_URL = environment.publicUrl;
  private VERSION = environment.version;

  public constructor(public http: HttpClient) {}

  public get<T>(endPoint: string, options?: ApiRequestOptions, publicUrl?: boolean): Observable<T> {
    const apiUrl = publicUrl ? this.PUBLIC_URL : this.API_URL + this.VERSION;
    return this.http.get<T>(`${apiUrl}${endPoint}`, options);
  }

  public post<T>(endPoint: string, params: any, options?: ApiRequestOptions, publicUrl?: boolean): Observable<T> {
    const apiUrl = publicUrl ? this.PUBLIC_URL : this.API_URL + this.VERSION;
    return this.http.post<T>(`${apiUrl}${endPoint}`, params, options);
  }

  public put<T>(endPoint: string, params: any, options?: ApiRequestOptions): Observable<T> {
    return this.http.put<T>(`${this.API_URL}${this.VERSION}${endPoint}`, params, options);
  }

  public delete<T>(endPoint: string, options?: ApiRequestOptions): Observable<T> {
    return this.http.delete<T>(`${this.API_URL}${this.VERSION}${endPoint}`, options);
  }

  public patch<T>(endPoint: string, params: any, options?: ApiRequestOptions): Observable<T> {
    return this.http.patch<T>(`${this.API_URL}${this.VERSION}${endPoint}`, params, options);
  }
}
