import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { userReducer, UserState } from './user/user.reducer';
import { UserEffects } from './user/user.effects';
import { RouterStateUrl } from '../core/custom-route-serializer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as userActions from './user/user.actions';

export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  user: UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  user: userReducer,
};

export const effects = [UserEffects];

export const metaReducers: MetaReducer<AppState>[] = [clearState];

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === userActions.logout.type) {
      state = {};
    }
    return reducer(state, action);
  };
}
