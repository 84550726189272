import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { AppState } from '../index';
import { User } from '../../models/user';
import { APP_ROUTES } from '../../app.routes';
import * as userActions from './user.actions';
import { ssoLogin } from './user.actions';
import { AuthService } from '../../services/auth/auth.service';
import { DASHBOARD_ROUTES } from '../../dashboard/dashboard.routes';
import { AuthErrorResponse } from '../../models/auth-error-response';
import { checkIsUtAccount, ssoLogout } from '../../core/ut-account-helper';
import { CustomRouterNavigatedAction } from '../../core/custom-route-serializer';
import { selectFromLogin, selectInvitedResearcher, selectUser } from './user.selectors';
import { transformInvitedResearcherInfo } from '../../core/transform-response-helper';
import { DashboardCamelCasePipe } from '../../core/pipes/dashboard-camel-case/dashboard-camel-case.pipe';
import { clearSSO, clearToken, getToken, isSSOLogin, setSSOLogin, setToken } from '../../core/token-helper';

@Injectable()
export class UserEffects {
  loginRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
        filter((route: CustomRouterNavigatedAction) => route.payload.routerState.path.startsWith(APP_ROUTES.LOGIN)),
        tap((route: CustomRouterNavigatedAction) => {
          const params = route.payload.routerState.params;
          if (params && params.error) {
            params.error === 'approved'
              ? this.toastrService.error(
                  'Your account is not approved yet. You will be notified about approval decision soon.'
                )
              : this.toastrService.error(
                  'Your account is blocked. If you consider there has been a mistake do not hesitate on contacting us at dashboard-bms@utwente.nl.'
                );
          }
        })
      ),
    { dispatch: false }
  );

  ssoLoginRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
      filter((route: CustomRouterNavigatedAction) => route.payload.routerState.path.startsWith(APP_ROUTES.SSOLOGIN)),
      switchMap((route: CustomRouterNavigatedAction) => {
        const params = route.payload.routerState.params;
        if (params && params.token) {
          setSSOLogin(true);
          setToken(params.token);
          return [ssoLogin({ token: params.token }), userActions.getProfile()];
        }
        return of(userActions.noopAction());
      })
    )
  );

  registerRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
      filter((route: CustomRouterNavigatedAction) => route.payload.routerState.path.startsWith(APP_ROUTES.REGISTER)),
      switchMap((route: CustomRouterNavigatedAction) => {
        const params = route.payload.routerState.params;
        const queryParams = route.payload.routerState.queryParams;
        if (params && params.email && checkIsUtAccount(params.email)) {
          return of(userActions.utAccountRegister({ email: params.email }));
        }
        if (queryParams && queryParams.code) {
          return of(userActions.getInvitedInfo({ code: queryParams.code }));
        }
        return of(userActions.noopAction());
      })
    )
  );

  pwdResetRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
      filter((route: CustomRouterNavigatedAction) => route.payload.routerState.path.startsWith(APP_ROUTES.PWDRESET)),
      switchMap((route: CustomRouterNavigatedAction) => {
        const params = route.payload.routerState.params;
        if (params && params.pwdResetToken) {
          return of(
            userActions.pwdResetRouteNavigated({
              pwdResetToken: params.pwdResetToken,
            })
          );
        }
        return of(userActions.noopAction());
      })
    )
  );

  studiesRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CustomRouterNavigatedAction>(ROUTER_NAVIGATED),
      filter((route: CustomRouterNavigatedAction) => {
        return (
          route.payload.routerState.path === DASHBOARD_ROUTES.ROOT ||
          route.payload.routerState.path === DASHBOARD_ROUTES.STUDIES
        );
      }),
      withLatestFrom(this.store.select(selectUser)),
      map(([action, user]) => userActions.getUserStudyTypes({ userId: user.id }))
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getProfile),
      withLatestFrom(this.store.select(selectUser)),
      switchMap(([action, user]) => {
        const token = getToken();
        if (token && !user) {
          return this.authService.getProfile().pipe(
            map((newUser) => {
              return userActions.getProfileSuccess({
                token,
                user: this.dashboardCamelCasePipe.keysToCamelCase(newUser),
              })
            }),
            catchError((err: AuthErrorResponse) => of(userActions.verifyLoginError({ err })))
          );
        }
        return of(userActions.noopAction());
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login),
      switchMap(({ credentials }) => {
        return this.authService.login(credentials).pipe(
          map((authSetup) => {
            setSSOLogin(false);
            return userActions.saveAuthSetup({authSetup});
          }),
          catchError((err: AuthErrorResponse) => of(userActions.verifyLoginError({ err })))
        );
      })
    )
  );

  loginTestEnv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loginTestEnv),
      switchMap(({ credentials }) => {
        return this.authService.loginTestEnv(credentials).pipe(
          map( (response) => {
            setSSOLogin(false);
            return userActions.verifyLoginSuccess({
              token: response.headers.get('authorization'),
              user: this.dashboardCamelCasePipe.keysToCamelCase(response.body),
            });
          }),
          catchError((err: AuthErrorResponse) => of(userActions.verifyLoginError({ err })))
        );
      })
    )
  );

  verifyLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.verifyLogin),
      switchMap(({ credentials }) => {
        return this.authService.tfaVerify(credentials).pipe(
          map((response) => {
            return userActions.verifyLoginSuccess({
              token: response.headers.get('Authorization'),
              user: this.dashboardCamelCasePipe.keysToCamelCase(response.body),
            });
          }),
          catchError((err: AuthErrorResponse) => of(userActions.verifyLoginError({ err })))
        );
      })
    )
  );

  verifyLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.verifyLoginSuccess),
        withLatestFrom(this.store.select(selectFromLogin)),
        tap(([action, fromLogin]) => {
          if (fromLogin) {
            setToken(action.token);
            this.router.navigate([APP_ROUTES.ROOT]);
          }
        })
      ),
    { dispatch: false }
  );

  getProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.getProfileSuccess),
        withLatestFrom(this.store.select(selectFromLogin)),
        tap(([action, fromLogin]) => {
          if (isSSOLogin()) {
            this.router.navigate([APP_ROUTES.ROOT]);
            setSSOLogin(false);
          }
        })
      ),
    { dispatch: false }
  );

  verifyLoginError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.verifyLoginError),
        tap(({ err }) => {
          clearToken();
          this.toastrService.error(err.error.error);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.logout),
        tap(({ email }) => {
          if (isSSOLogin()) {
            ssoLogout(email);
          }
          clearToken();
          clearSSO();
          this.router.navigate([APP_ROUTES.LOGIN]);
          this.toastrService.info('You are logged out');
        })
      ),
    { dispatch: false }
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.register),
      switchMap(({ researcher }) => {
        return this.authService.register(researcher).pipe(
          map((newUser: User) => userActions.registerSuccess({ newUser })),
          catchError((err: AuthErrorResponse) => of(userActions.registerError({ err })))
        );
      })
    )
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.registerSuccess),
        tap(() => this.toastrService.success('User successfully registered'))
      ),
    { dispatch: false }
  );

  registerError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.registerError),
        tap(({ err }) => {
          const error: any = err.error;
          if(error.email) {
            error.email.forEach((errValue) => this.toastrService.error(errValue));
          }
          else if (error.projectId) {
            this.toastrService.error(error.projectId);
          } else {
            this.toastrService.error(error.error);
          }
        })
      ),
    { dispatch: false }
  );

  getInvitedInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getInvitedInfo),
      switchMap(({ code }) => {
        return this.authService.getInvitedResearcherInfo(code).pipe(
          map((invitedResearcherArray) =>
            userActions.getInvitedInfoSuccess({
              invitedResearcher: transformInvitedResearcherInfo(invitedResearcherArray[0], code),
            })
          ),
          catchError((err: AuthErrorResponse) => of(userActions.getInvitedInfoError({ err })))
        );
      })
    )
  );

  registerInvitedApplicant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.registerInvitedApplicant),
      withLatestFrom(this.store.select(selectInvitedResearcher)),
      switchMap(([action, invitedInfo]) => {
        return this.authService.registerInvitedApplicant(action.invitedApplicant, invitedInfo.code).pipe(
          map((newUser: User) => userActions.registerSuccess({ newUser })),
          catchError((err: AuthErrorResponse) => of(userActions.registerError({ err })))
        );
      })
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.forgotPassword),
      switchMap(({ email }) =>
        this.authService.forgotPassword(email).pipe(
          map(() => userActions.forgotPasswordSuccess()),
          catchError((err: AuthErrorResponse) => of(userActions.forgotPasswordError({ err })))
        )
      )
    )
  );

  forgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.forgotPasswordSuccess),
        tap(() => this.toastrService.info("We've sent you an email with instructions to reset your password"))
      ),
    { dispatch: false }
  );

  forgotPasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.forgotPasswordError),
        tap(({ err }) => this.toastrService.error(err.error.error))
      ),
    { dispatch: false }
  );

  pwdReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.pwdReset),
      switchMap(({ pwdResetRequest }) =>
        this.authService.pwdReset(pwdResetRequest).pipe(
          map((response) => userActions.pwdResetSuccess()),
          catchError((err: AuthErrorResponse) => of(userActions.pwdResetError({ err })))
        )
      )
    )
  );

  pwdResetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.pwdResetSuccess),
        tap(() => {
          this.toastrService.info('Your password has been reset');
          this.router.navigate([APP_ROUTES.LOGIN]);
        })
      ),
    { dispatch: false }
  );

  pwdResetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.pwdResetError),
        tap(() => this.toastrService.error('This reset link has expired or is invalid'))
      ),
    { dispatch: false }
  );

  getUserStudyTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getUserStudyTypes),
      switchMap(({ userId }) => {
        return this.authService.getUserStudyTypes(userId).pipe(
          map((userStudyTypes) => {
            const uniqueStudyTypes = userStudyTypes.filter((type, index) => userStudyTypes.indexOf(type) === index);
            return userActions.getUserStudyTypesSuccess({
              userStudyTypes: uniqueStudyTypes,
            });
          }),
          catchError((err: AuthErrorResponse) => of(userActions.getUserStudyTypesError({ err })))
        );
      })
    )
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => userActions.getProfile())
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private dashboardCamelCasePipe: DashboardCamelCasePipe,
    private toastrService: ToastrService
  ) {}
}
