<div class="dashboard-material-theme dashboard-login" fxLayout="column" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxFill>
    <a href="https://bmslab.utwente.nl/" title="BMS LAB" target="_blank">
      <img class="dashboard-login__logo-left" src="assets/images/BMSlablogo.png" alt="page logo" />
    </a>
    <a href="https://www.utwente.nl/" title="University of Twente" target="_blank">
      <img class="dashboard-login__logo-right" src="assets/images/UTlogo.png" alt="page logo" />
    </a>
  </div>
  <mat-card class="dashboard-login__card">
    <mat-card-header>
      <mat-card-title id="login-title">{{ 'Twente Intervention and Interaction Machine' }}</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="screenToShow === TFA_SETUP_SCREEN.LOGIN; else tfaScreens">
      <mat-card-content>
        <form
          [formGroup]="loginForm"
          fxLayout="column"
          fxLayoutAlign="space-around stretch"
          class="dashboard-login__card-form"
        >
          <div class="dashboard-login__card-form-email-prompt" *ngIf="forgot">
            Please enter your e-mail address so we can send you a link to reset your password.
          </div>
          <mat-form-field>
            <mat-label>E-mail address or m-number</mat-label>
            <input
              id="login-input-email"
              matInput
              (keydown.enter)="checkEmail()"
              formControlName="email"
              placeholder="E-mail address or m-number"
            />
            <mat-error *ngIf="email.hasError('required')"> E-mail or m-number is <strong>required</strong></mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="passwordControlVisible">
            <mat-label>Password</mat-label>
            <input
              #passwordInput
              id="login-input-password"
              matInput
              type="password"
              (keydown.enter)="login()"
              formControlName="password"
              placeholder="Password"
            />
            <mat-error *ngIf="password.hasError('required')"> Password is <strong>required</strong></mat-error>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-evenly center">
        <ng-container *ngIf="!forgot">
          <ng-container *ngTemplateOutlet="passwordControlVisible ? loginButtons : defaultButtons"></ng-container>
        </ng-container>
        <ng-container *ngIf="forgot">
          <ng-container *ngTemplateOutlet="forgotPasswordButtons"></ng-container>
        </ng-container>
      </mat-card-actions>
    </ng-container>
    <ng-template #tfaScreens>
      <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
        <div
          *ngIf="screenToShow === TFA_SETUP_SCREEN.SETUP"
          fxLayout="column"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
        >
          <h4
            matTooltip="Use an app like Google Authenticator, Microsoft Authenticator, Duo.."
            matTooltipPosition="right"
          >
            Scan the QR code or enter the secret key in your Authenticator
          </h4>
          <ng-container *ngIf="authSetup?.qrCodeSvg">
            <div [innerHTML]="svg"></div>
          </ng-container>
          <p>Title: <strong>BMS Lab</strong></p>
          <p>
            Secret Key: <strong>{{ authSetup?.secretKey }}</strong>
          </p>
          <p>Auth Type: <strong>Time Based - OTP</strong></p>
        </div>
        <form
          [formGroup]="loginForm"
          fxLayout="column"
          fxLayoutAlign="space-around stretch"
          class="dashboard-login__card-form"
        >
          <div class="dashboard-login__card-form-email-prompt" *ngIf="forgot">
            Please enter your e-mail address so we can send you a link to reset your password.
          </div>
          <mat-form-field>
            <mat-label>Please enter Passcode from your Authenticator App</mat-label>
            <input
              #passcodeInput
              id="login-input-passcode"
              matInput
              formControlName="passcode"
              (keydown.enter)="verifyTfa()"
              placeholder="Passcode"
              autofocus
            />
            <mat-error *ngIf="passcode.hasError('required')">Passcode is <strong>required</strong></mat-error>
            <mat-error *ngIf="passcode.hasError('pattern')">Passcode is a <strong>numeric</strong> value</mat-error>
            <mat-error *ngIf="passcode.hasError('maxlength')">
              Passcode should have <strong>at most {{ passcode.errors?.maxlength.requiredLength }}</strong> digits
            </mat-error>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="space-evenly center">
        <button mat-button color="accent" id="login-tfa-setup-btn-back" (click)="backFromPasscode()">
          {{ 'Back' | uppercase }}
        </button>
        <button mat-raised-button color="accent" id="login-tfa-setup-btn-login" (click)="verifyTfa()">
          {{ 'Login' | uppercase }}
        </button>
      </mat-card-actions>
    </ng-template>
  </mat-card>
</div>

<ng-template #defaultButtons>
  <button mat-raised-button color="accent" id="login-btn-new-account" (click)="openRegisterPage()">
    {{ 'New Account' | uppercase }}
  </button>
  <button mat-raised-button color="accent" id="login-btn-next" (click)="checkEmail()">
    {{ 'Next' | uppercase }}
  </button>
</ng-template>

<ng-template #loginButtons>
  <button mat-button color="accent" id="login-btn-back" (click)="back()">
    {{ 'Back' | uppercase }}
  </button>
  <button mat-raised-button color="accent" id="login-btn-login" (click)="login()">
    {{ 'Login' | uppercase }}
  </button>
  <button mat-button color="accent" id="login-btn-forgot-password" (click)="openForgotPasswordPage()">
    {{ 'Forgot password?' | uppercase }}
  </button>
</ng-template>

<ng-template #forgotPasswordButtons>
  <button mat-raised-button color="accent" id="login-btn-reset-password" (click)="resetPassword()">
    {{ 'Reset password' | uppercase }}
  </button>
  <button mat-button color="primary" id="login-btn-forgot-back" (click)="backFromForgot()">
    {{ 'Back' | uppercase }}
  </button>
</ng-template>
