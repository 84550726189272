export const STUDIES_ROUTES = {
  ROOT: '',
  HOME: 'home',
  CREATE_STUDY: 'study-details',
  DETAILS: 'details',
  SUBSCRIPTION: 'subscription',
  INTERVENTIONS: 'interventions',
  SESSIONS: 'sessions',
  INFORMATIVE_PAGES: 'information',
  MODULES: 'modules',
  CALCULATED_VARIABLES: 'calculated-variables',
  TIIM_PARTICIPANTS: 'tiim-participants',
  CC_PARTICIPANTS: 'cc-participants',
  METRICS: 'metrics',
  RESEARCHERS: 'researchers',
  TEMPLATES: 'templates',
  EMAILS: 'mails',
};
