import { Language, LanguageCode } from '../models/language';
import { HealthDataType } from '../models/health-data';

export const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const LANGUAGES: Language[] = [
  { code: LanguageCode.ENGLISH, name: 'English', nativeName: 'English' },
  { code: LanguageCode.GERMAN, name: 'German', nativeName: 'Deutsch' },
  { code: LanguageCode.DUTCH, name: 'Dutch', nativeName: 'Nederlands' },
  { code: LanguageCode.FRENCH, name: 'French', nativeName: 'Français' },
  { code: LanguageCode.ITALIAN, name: 'Italian', nativeName: 'Italiano' },
  { code: LanguageCode.SPANISH, name: 'Spanish', nativeName: 'Español' },
];

export const PRIVILEGE_SECTIONS = {
  STUDY_DETAILS: 'study-details',
  INTERVENTIONS: 'interventions',
  MODULES: 'modules',
  RESEARCHERS: 'researchers',
  PARTICIPANTS: 'participants',
  METRICS: 'metrics',
};

export const PRIVILEGE_ACTIONS = {
  HIDE: '',
  VIEW: 'view',
  ALL: 'all',
};

export const MODULE_ITEM_LABEL_IDENTIFIER = 'label';
export const MODULE_ITEM_QUESTION_IDENTIFIER = 'question';
export const MODULE_ITEM_DESCRIPTION_IDENTIFIER = 'description';
export const MODULE_ITEM_GRAPH_IDENTIFIER = 'graph';
export const MODULE_ITEM_CHOICES_IDENTIFIER = 'choices';
export const MODULE_ITEM_RANDOMIZE_IDENTIFIER = 'randomize';
export const MODULE_ITEM_EMOTION_IMAGE_IDENTIFIER = 'image';

export const MODULE_ITEM_DEFAULT_QUESTION = 'Enter your question in the left panel';
export const MODULE_ITEM_DEFAULT_BUTTON_LABEL = 'Ok';
export const MODULE_ITEM_DEFAULT_YES_LABEL = 'Yes';
export const MODULE_ITEM_DEFAULT_NO_LABEL = 'No';
export const MODULE_ITEM_DEFAULT_NO_OTHER_LABEL = 'No, Other..';
export const MODULE_ITEM_DEFAULT_QUADRANT_IMAGE = '/assets/images/default-emotion-quadrant-image.png';
export const MODULE_ITEM_DEFAULT_IVY_CORRECT_AUDIO = 'audios/study000/module000/correct_choice.wav';
export const MODULE_ITEM_DEFAULT_IVY_WRONG_AUDIO = 'audios/study000/module000/wrong_choice.mp3';
export const MODULE_ITEM_IVY_TOP_ZONE = '0';
export const MODULE_ITEM_IVY_BOTTOM_ZONE = '1';
export const MODULE_ITEM_DEFAULT_IVY_WRONG_COLOR = '#FBCA91';
export const MODULE_ITEM_DEFAULT_IVY_CORRECT_COLOR = '#FE9262';
export const MODULE_ITEM_DEFAULT_IVY_DRAG_WORD_COLOR = '#D1D3D1';
export const MODULE_ITEM_DEFAULT_IVY_COLOR = '#009688';

export const CALCULATED_VARIABLE_FUNCTIONS = [
  {
    id: '+',
    calculationName: '+',
    description: 'Returns the sum of the indicated variables.',
  },
  {
    id: '-',
    calculationName: '-',
    description: 'Returns the subtraction of the indicated variables.',
  },
  {
    id: '*',
    calculationName: '*',
    description: 'Returns the multiplication of the indicated variable.',
  },
  {
    id: '/',
    calculationName: '/',
    description: 'Returns the division of the indicated variables.',
  },
  {
    id: '^',
    calculationName: '^',
    description: 'Returns the exponential value of the indicated variable or numerical input.',
  },
  {
    id: 'SUM_ANS(N)',
    calculationName: 'SUM_ANS(',
    description: 'Returns the addition of the answered numerical responses from the indicated parameter(variable).',
  },
  {
    id: 'NUM_PS(N)',
    calculationName: 'NUM_PS(',
    description: 'Returns the total number of participants for the indicated variable.',
  },
  {
    id: 'NUM_PS_ANS(N)',
    calculationName: 'NUM_PS_ANS(',
    description: 'Returns the number of participants that responded to the indicated variable.',
  },
  {
    id: 'MODE_ANS(N)',
    calculationName: 'MODE_ANS(',
    description: 'Returns the most frequent responded number over the indicated variable.',
  },
  {
    id: 'COUNT_ANS(VAR,VAL)',
    calculationName: 'COUNT_ANS(',
    description: 'Returns the count of occurrences from the responded value of the indicated variable.',
  },
];

export const SANITIZER_TYPES = {
  HTML: 'html',
  STYLE: 'style',
  SCRIPT: 'script',
  URL: 'url',
  RESOURCE_URL: 'resourceUrl',
};

export const TINYMCE_ITEM_DEFAULT_VALUE = { text: '', value: '' };

export const PARTICIPANT_ITEMS = [
  TINYMCE_ITEM_DEFAULT_VALUE,
  { text: 'First name', value: '[user-firstname]' },
  { text: 'Last name', value: '[user-lastname]' },
  { text: 'E-mail', value: '[user-email]' },
];

export const SECTION_TYPES = ['Description', 'Generic graph', 'Profile', 'Dimension'];

export const IMAGE_SIZES = {
  BIG: 'big',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const DEFAULT_DIMENSION_COLOR = '#000';

export const OPEN_TIMING_RULES = [
  { type: 'relative_from_intervention', label: 'On a relative time after intervention started' },
  { type: 'relative_from_module', label: 'On a relative time after a module has finished' },
  { type: 'unfinished_module', label: "When a module hasn't been finished" },
  { type: 'based_on_answer', label: 'Based on an answer on a module item' },
  { type: 'fixed_date', label: 'On a fixed date' },
  { type: 'calculated_variable', label: 'Based on calculated variable' },
];

export const CLOSING_TIMING_RULES = [
  { type: 'based_on_answer', label: 'Based on an answer on a module item' },
  { type: 'fixed_date', label: 'On a fixed date' },
  { type: 'relative_after_module', label: 'Based on a relative time after module has started'}
];

export const ESM_TIMING_RULES = [
  { type: 'esm_fixed_date', label: 'Repeat module based on fixed date' },
  { type: 'esm_relative_date', label: 'Repeat module based on a relative time after participant started intervention' },
];

export const HEALTH_DATA_VALUES = {
  HEART_RATE: 'heart_rate',
  STEPS: 'steps',
  SLEEP: 'sleep',
};

export const HEALTH_DATA_SLEEP_VALUES = {
  OUT_OF_BED: 'Out Of Bed',
  IN_BED: 'In Bed',
  AWAKE: 'Awake',
  ASLEEP: 'Asleep',
  REM: 'Rem',
  LIGHT: 'Light',
  DEEP: 'Deep'
};

export const HEALTH_DATA_TYPES: HealthDataType[] = [
  {
    value: HEALTH_DATA_VALUES.HEART_RATE,
    translation: 'Heart Rate',
    showData: false,
  },
  {
    value: HEALTH_DATA_VALUES.STEPS,
    translation: 'Steps',
    showData: false,
  },
  {
    value: HEALTH_DATA_VALUES.SLEEP,
    translation: 'Sleep',
    showData: false,
  }
];

export const RANDOMIZATION_VALUES = {
  ROUTING: 'routing',
  MODULE: 'module',
  CATEGORY: 'category',
};

export const MODULE_TYPE = {
  ESM: 'esm',
  NORMAL: 'normal',
};

export const UM_PARTICIPANTS_PAGE_SIZE = 50;
export const UM_PARTICIPANTS_AVAILABLE_PAGE_SIZES = [50, 100, 150];

export const STUDY_PARTICIPANT_GROUPS = {
  UNASSIGNED: 'Unassigned participants',
  REJECTED: 'Rejected participants',
  UNSUBSCRIBED: 'Unsubscribed participants',
};
