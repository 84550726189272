import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterNavigatedPayload, RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  path: string;
}

export declare interface CustomRouterNavigatedAction {
  type: string;
  payload: RouterNavigatedPayload<RouterStateUrl>;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    let path;
    if (route.routeConfig) {
      path = route.routeConfig.path;
    }

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, path };
  }
}
