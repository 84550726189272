import { InvitedResearcher } from '../models/invited-researcher';

export function transformInvitedResearcherInfo(invitedResearcherResponse, code): InvitedResearcher {
  const invitedResearcher = {
    id: invitedResearcherResponse.id,
    firstName: invitedResearcherResponse.firstname,
    lastName: invitedResearcherResponse.lastname,
    email: invitedResearcherResponse.email,
    code: code,
    studyTypes: {},
  };
  invitedResearcher.studyTypes[invitedResearcherResponse.type] = true;
  return invitedResearcher;
}
