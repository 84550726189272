import { Language, LanguageCode } from 'src/app/models/language';
import { LANGUAGES } from './dashboard-constants';

export function transformLanguageCodesToLanguageEntities(languages: string[]): Language[] {
  const transformedLanguages: Language[] = [];
  for (const lang of languages) {
    if (lang === LanguageCode.ENGLISH) {
      transformedLanguages.push(LANGUAGES[0]);
    } else if (lang === LanguageCode.GERMAN) {
      transformedLanguages.push(LANGUAGES[1]);
    } else if (lang === LanguageCode.DUTCH) {
      transformedLanguages.push(LANGUAGES[2]);
    } else if (lang === LanguageCode.FRENCH) {
      transformedLanguages.push(LANGUAGES[3]);
    } else if (lang === LanguageCode.ITALIAN) {
      transformedLanguages.push(LANGUAGES[4]);
    } else if (lang === LanguageCode.SPANISH) {
      transformedLanguages.push(LANGUAGES[5]);
    }
  }
  return setLanguageTabIndex(transformedLanguages);
}

export function setLanguageTabIndex(languages: Language[]): Language[] {
  const indexedLanguages: Language[] = [];
  for (let i = 0; i < languages.length; i++) {
    indexedLanguages.push({ ...languages[i], tabIndex: i });
  }
  return indexedLanguages;
}

export function getLanguageByCode(languageCode: string): Language {
  return LANGUAGES.find((language) => language.code === languageCode);
}
