import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from './user.reducer';
import { SectionType } from '../../models/section-type';
import { ActionType } from '../../models/action-type';
import { selectSelectedStudy } from '../../dashboard/state/study/study.selectors';

export const userFeatureKey = 'user';

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectIsLoadingUser = createSelector(selectUserState, (userState) => userState?.loadingUser);

export const selectUserLoaded = createSelector(selectUserState, (userState) => userState?.userLoaded);

export const selectUser = createSelector(selectUserState, (userState) => userState?.user);

export const selectIsAttemptingRegistration = createSelector(
  selectUserState,
  (userState) => userState?.attemptingRegistration
);

export const selectRegistered = createSelector(selectUserState, (userState) => userState?.registered);

export const selectIsUtAccount = createSelector(selectUserState, (userState) => userState?.isUtAccount);

export const selectIsInvited = createSelector(selectUserState, (userState) => userState?.isInvited);

export const selectInvitedFail = createSelector(selectUserState, (userState) => userState?.invitedFail);

export const selectInvitedErrorMessage = createSelector(selectUserState, (userState) => userState?.invitedFailErrorMessage);

export const selectPwdResetToken = createSelector(selectUserState, (userState) => userState?.pwdResetToken);

export const selectInvitedResearcher = createSelector(selectUserState, (userState) => userState?.invitedResearcher);

export const selectFromLogin = createSelector(selectUserState, (userState) => userState?.fromLogin);

export const selectUserStudyTypes = createSelector(selectUserState, (userState) => userState?.userStudyTypes);

export const selectIsAdmin = createSelector(selectUser, (user) => {
  return user?.superuser === 1;
});

export const selectIsCreator = createSelector(selectUser, (user) => {
  return user?.createStudies === 1;
});

export const selectIsInitialized = createSelector(selectUserState, (userState) => userState?.initialized);

export const selectAuthSetup = createSelector(selectUserState, (userState) => userState?.authSetup);

export const selectTfaDisabled = createSelector(selectUserState, (userState) => userState?.tfaDisabled);

export const selectIsGranted = createSelector(
  selectUser,
  selectIsAdmin,
  selectSelectedStudy,
  (user, isAdmin, selectedStudy, { section, action }) => {
    const studyId = selectedStudy?.id;

    if (!user) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    if (selectedStudy?.researcherId === user?.id) {
      return true;
    }

    for (const privilege of user.privileges) {
      if (
        privilege.studyId === studyId &&
        (privilege.section === section || privilege.section === SectionType.ALL) &&
        (privilege.action === action || privilege.action === ActionType.ALL)
      ) {
        return true;
      }
    }

    return false;
  }
);
