export interface Language {
  code?: string;
  name?: string;
  nativeName?: string;
  tabIndex?: number;
}

export enum LanguageCode {
  ENGLISH = 'en',
  DUTCH = 'nl',
  GERMAN = 'de',
  FRENCH = 'fr',
  ITALIAN = 'it',
  SPANISH = 'es'
}
