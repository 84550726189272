import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { AppState } from '../../../state';
import { getToken } from '../../token-helper';
import { APP_ROUTES } from '../../../app.routes';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (getToken()) {
      return true;
    }

    this.router.navigate([APP_ROUTES.LOGIN]);
    return false;
  }

  constructor(private store: Store<AppState>, private router: Router) {}
}
