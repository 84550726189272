import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiService } from '../../core/services/api/api.service';
import { User } from '../../models/user';
import { Credentials } from '../../models/credentials';
import { RegisterRequest } from '../../models/register-request';
import { PwdResetRequest } from 'src/app/models/pwd-reset-request';
import { InvitedResearcher } from '../../models/invited-researcher';
import { InvitedRegisterRequest } from '../../models/invited-register-request';
import { DashboardSnakeCasePipe } from '../../core/pipes/dashboard-snake-case/dashboard-snake-case.pipe';
import { AuthSetup } from '../../models/auth-setup';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private apiService: ApiService, private dashboardSnakeCasePipe: DashboardSnakeCasePipe) {}

  forgotPassword(email: string): Observable<JSON> {
    return this.apiService.post<JSON>(`/dashboard/pwd/forgot`, { email });
  }

  login(credentials: Credentials): Observable<AuthSetup> {
    return this.apiService.post<AuthSetup>(`/dashboard/login`, credentials);
  }

  loginTestEnv(credentials: Credentials): Observable<HttpResponse<AuthSetup>> {
    return this.apiService.post<HttpResponse<AuthSetup>>(`/dashboard/login`, credentials, {observe: 'response'});
  }

  tfaVerify(credentials: Credentials): Observable<HttpResponse<AuthSetup>> {
    return this.apiService.post<HttpResponse<AuthSetup>>(`/dashboard/tfa/verify`, credentials, { observe: 'response' });
  }

  register(researcher: RegisterRequest): Observable<User> {
    return this.apiService.post<User>(`/dashboard/researcher`, researcher);
  }

  getInvitedResearcherInfo(code: string): Observable<InvitedResearcher[]> {
    return this.apiService.get<InvitedResearcher[]>(`/dashboard/register/${code}`);
  }

  registerInvitedApplicant(invitedApplicant: InvitedRegisterRequest, code: string): Observable<User> {
    return this.apiService.put<User>(
      `/dashboard/researcher/${code}`,
      this.dashboardSnakeCasePipe.keysToSnakeCase(invitedApplicant)
    );
  }

  pwdReset(pwdResetRequest: PwdResetRequest): Observable<JSON> {
    return this.apiService.post<JSON>(`/dashboard/pwd/reset`, pwdResetRequest);
  }

  getProfile(): Observable<User> {
    return this.apiService.get<User>(`/dashboard/profile`);
  }

  getUserStudyTypes(userId: number): Observable<string[]> {
    return this.apiService.get<string[]>(`/dashboard/researcher_study_types/${userId}`);
  }
}
