import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../../models/user';
import * as userActions from './user.actions';
import { InvitedResearcher } from '../../models/invited-researcher';
import { AuthSetup } from '../../models/auth-setup';

export interface UserState {
  loadingUser: boolean;
  userLoaded: boolean;
  user: User;
  attemptingRegistration: boolean;
  registered: boolean;
  isUtAccount: boolean;
  isInvited: boolean;
  invitedFail: boolean;
  invitedFailErrorMessage: string;
  invitedResearcher: InvitedResearcher;
  pwdResetToken: string;
  initialized: boolean;
  fromLogin: boolean;
  userStudyTypes: string[];
  authSetup?: AuthSetup;
  tfaDisabled: boolean;
}

export const initialUserState: UserState = {
  loadingUser: false,
  userLoaded: false,
  user: null,
  attemptingRegistration: false,
  registered: false,
  isUtAccount: false,
  isInvited: false,
  invitedFail: false,
  invitedFailErrorMessage: null,
  invitedResearcher: null,
  pwdResetToken: '',
  initialized: false,
  fromLogin: false,
  userStudyTypes: [],
  authSetup: null,
  tfaDisabled: false,
};

export const reducer = createReducer(
  initialUserState,
  on(userActions.verifyLogin, (state) => {
    return {
      ...state,
      loadingUser: true,
      userLoaded: false,
      fromLogin: true,
    };
  }),
  on(userActions.ssoLogin, (state) => {
    return {
      ...state,
      loadingUser: true,
      userLoaded: false,
      fromLogin: true,
    };
  }),
  on(userActions.verifyLoginSuccess, (state, { user }) => {
    return {
      ...state,
      loadingUser: false,
      userLoaded: true,
      initialized: true,
      user,
      tfaDisabled: true,
      fromLogin: true,
    };
  }),
  on(userActions.getProfileSuccess, (state, { user }) => {
    return {
      ...state,
      loadingUser: false,
      userLoaded: true,
      initialized: true,
      user,
      tfaDisabled: true,
      fromLogin: false,
    };
  }),
  on(userActions.verifyLoginError, (state) => {
    return {
      ...state,
      loadingUser: false,
      userLoaded: false,
      initialized: true,
      user: null,
    };
  }),
  on(userActions.register, (state) => {
    return {
      ...state,
      attemptingRegistration: true,
      registered: false,
    };
  }),
  on(userActions.registerSuccess, (state) => {
    return {
      ...state,
      attemptingRegistration: false,
      registered: true,
    };
  }),
  on(userActions.registerError, (state) => {
    return {
      ...state,
      attemptingRegistration: false,
      registered: false,
    };
  }),
  on(userActions.utAccountRegister, (state, { email }) => {
    return {
      ...state,
      isUtAccount: true,
      user: { email },
    };
  }),
  on(userActions.getInvitedInfo, (state) => {
    return {
      ...state,
      isInvited: true,
    };
  }),
  on(userActions.getInvitedInfoSuccess, (state, { invitedResearcher }) => {
    return {
      ...state,
      invitedResearcher,
      invitedFail: false,
    };
  }),
  on(userActions.getInvitedInfoError, (state, { err }) => {
    return {
      ...state,
      invitedFailErrorMessage: err.error.error,
      invitedFail: true,
    };
  }),
  on(userActions.pwdResetRouteNavigated, (state, { pwdResetToken }) => {
    return {
      ...state,
      pwdResetToken,
    };
  }),
  on(userActions.enableRegistration, (state) => {
    return {
      ...state,
      registered: false,
    };
  }),
  on(userActions.noopAction, (state) => {
    return {
      ...state,
      initialized: true,
    };
  }),
  on(userActions.getUserStudyTypesSuccess, (state, { userStudyTypes }) => {
    return {
      ...state,
      userStudyTypes,
    };
  }),
  on(userActions.saveAuthSetup, (state, { authSetup }) => {
    return {
      ...state,
      authSetup,
      tfaDisabled: false,
    };
  })
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
