import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_ROUTES } from '../app.routes';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { clearToken, getToken, tokenIsExpired } from './token-helper';

@Injectable({
  providedIn: 'root',
})
export class TokenErrorInterceptor implements HttpInterceptor {
  clearTokenAndRedirect() {
    clearToken();
    this.router.navigate([APP_ROUTES.LOGIN]);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (getToken() && tokenIsExpired()) {
      this.toastrService.error('Your session has expired. Please login again.', 'Error');
      this.clearTokenAndRedirect();
      return;
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401 && typeof err.error === 'string') {
          this.toastrService.error(err.error, 'Error');
          this.clearTokenAndRedirect();
        }

        //Server Error
        else if (err instanceof HttpErrorResponse && err.status === 500) {
          this.toastrService.error('Server Error', 'Error');
        }
        return throwError(err);
      })
    );
  }

  constructor(private toastrService: ToastrService, private router: Router) {}
}
