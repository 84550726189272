import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardCamelCase',
})
export class DashboardCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });
  }

  keysToCamelCase(object: any) {
    if (object === Object(object) && !Array.isArray(object) && typeof object !== 'function') {
      const result = {};
      Object.keys(object).forEach((k) => {
        result[this.transform(k)] = this.keysToCamelCase(object[k]);
      });
      return result;
    } else if (Array.isArray(object)) {
      return object.map((i) => this.keysToCamelCase(i));
    }
    return object;
  }
}
