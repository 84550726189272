import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { LoginComponent } from './guest/login/login.component';
import { RegisterComponent } from './guest/register/register.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { NonAuthGuard } from './core/guards/non-auth/non-auth.guard';
import { PwdResetComponent } from './guest/pwd-reset/pwd-reset.component';

const routes: Routes = [
  {
    path: APP_ROUTES.ROOT,
    loadChildren: () => import('./dashboard/dashboard.module').then((d) => d.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.LOGIN,
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: APP_ROUTES.LOGIN + '/:error',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: APP_ROUTES.SSOLOGIN + '/:token',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: APP_ROUTES.REGISTER,
    component: RegisterComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: APP_ROUTES.REGISTER + '/:email',
    component: RegisterComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: APP_ROUTES.PWDRESET + '/:pwdResetToken',
    component: PwdResetComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: APP_ROUTES.ROOT },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
