import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { AppState } from '../../state';
import {forgotPassword, login, loginTestEnv, saveAuthSetup, verifyLogin} from '../../state/user/user.actions';
import { Credentials } from '../../models/credentials';
import { APP_ROUTES } from '../../app.routes';
import { checkIsUtAccount, ssoLogin } from '../../core/ut-account-helper';
import { selectAuthSetup, selectTfaDisabled } from '../../state/user/user.selectors';
import { AuthSetup } from '../../models/auth-setup';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

const TFA_SETUP_SCREEN = {
  LOGIN: 'login',
  SETUP: 'setup',
  VERIFY: 'verify',
};

@Component({
  selector: 'dashboard-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('passwordInput', { static: false }) passwordInput: ElementRef;
  @ViewChild('passcodeInput', { static: false }) passcodeInput: ElementRef;

  loginForm: FormGroup;
  passwordControlVisible = false;
  forgot = false;

  TFA_SETUP_SCREEN = TFA_SETUP_SCREEN;

  authSetup: AuthSetup;
  tfaDisabled: boolean;
  screenToShow = this.TFA_SETUP_SCREEN.LOGIN;

  private subscriptions = new Subscription();
  svg: SafeHtml;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private router: Router, private sanitizer: DomSanitizer) {}

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  get passcode(): AbstractControl {
    return this.loginForm.get('passcode');
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: [''],
      passcode: [''],
    });

    this.subscriptions.add(
      this.store.select(selectTfaDisabled).subscribe((tfaDisabled) => {
        this.tfaDisabled = tfaDisabled;
      })
    );

    this.subscriptions.add(
      this.store.select(selectAuthSetup).subscribe((authSetup) => {
        this.authSetup = authSetup;
        if (this.authSetup) {
          this.svg = this.sanitizer.bypassSecurityTrustHtml(this.authSetup.qrCodeSvg);
        }
        this.screenToShow =
          !authSetup || (authSetup && this.tfaDisabled === true)
            ? this.TFA_SETUP_SCREEN.LOGIN
            : authSetup?.authenticated
            ? this.TFA_SETUP_SCREEN.VERIFY
            : this.TFA_SETUP_SCREEN.SETUP;
      })
    );
  }

  updatePasswordFieldVisibilityAndValidity(validators: ValidatorFn[]) {
    this.passwordControlVisible = !this.passwordControlVisible;
    this.password.setValidators(validators);
    this.loginForm.updateValueAndValidity();
  }

  checkEmail(): void {
    if (this.email.invalid) {
      return;
    }

    if (checkIsUtAccount(this.email.value)) {
      ssoLogin();
      return;
    }

    if (this.email.value.includes('@')) {
      this.email.disable();
      this.updatePasswordFieldVisibilityAndValidity([Validators.required]);
    }

    setTimeout(() => {
      this.passwordInput?.nativeElement?.focus();
    }, 0);
  }

  back(): void {
    this.email.enable();
    this.updatePasswordFieldVisibilityAndValidity([]);
    this.password.setValue('');
    this.password.markAsUntouched();
  }

  backFromPasscode(): void {
    this.passcode.setValidators([]);
    this.passcode.setValue('');
    this.store.dispatch(saveAuthSetup({ authSetup: null }));
  }

  backFromForgot(): void {
    this.forgot = false;
    this.updatePasswordFieldVisibilityAndValidity([]);
  }

  login(): void {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.invalid) {
      return;
    }

    const credentials: Credentials = {
      email: this.email.value,
      password: this.password.value,
    };

    if (environment.environmentName === 'test') {
      this.store.dispatch(loginTestEnv({credentials}));
    } else {
      this.store.dispatch(login({ credentials }));
    }

    this.passcode.setValidators([Validators.required, Validators.maxLength(6), Validators.pattern('[0-9]*')]);
    this.passcode.markAsUntouched();

    setTimeout(() => {
      this.passcodeInput?.nativeElement?.focus();
    }, 0);
  }

  verifyTfa(): void {
    if (!this.passcode.value) {
      return;
    }

    this.store.dispatch(verifyLogin({ credentials: this.loginForm.getRawValue() }));
  }

  openForgotPasswordPage(): void {
    this.forgot = true;
    this.passwordControlVisible = !this.passwordControlVisible;
    this.loginForm.updateValueAndValidity();
  }

  openRegisterPage(): void {
    this.router.navigate([APP_ROUTES.REGISTER]);
  }

  resetPassword(): void {
    this.store.dispatch(forgotPassword({ email: this.email.value }));
    this.backFromForgot();
  }
}
