import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_TABS_CONFIG, MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClipboardModule } from '@angular/cdk/clipboard';

const modules = [
  MatButtonModule,
  MatCheckboxModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDividerModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatSidenavModule,
  MatChipsModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatTooltipModule,
  MatNativeDateModule,
  MatTabsModule,
  MatDatepickerModule,
  NgxMatTimepickerModule,
  NgxMatDatetimePickerModule,
  NgxMatMomentModule,
  MatRadioModule,
  MatSlideToggleModule,
  DragDropModule,
  MatSliderModule,
  MatPaginatorModule,
  ClipboardModule,
];

@NgModule({
  imports: [modules],
  exports: [modules],
})
export class AngularMaterialModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AngularMaterialModule,
      providers: [
        {
          provide: NGX_MAT_DATE_FORMATS,
          useValue: {
            parse: {
              dateInput: 'DD-MM-YYYY HH:mm',
            },
            display: {
              dateInput: 'DD-MM-YYYY HH:mm',
              monthYearLabel: 'MMM YYYY',
              dateA11yLabel: 'LL',
              monthYearA11yLabel: 'MMMM YYYY',
            },
          },
        },
        {
          provide: MAT_TABS_CONFIG,
          useValue: {
            fitInkBarToContent: true,
          },
        },
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: { minWidth: '40%', maxWidth: '70%', hasBackdrop: true },
        },
        {
          provide: MAT_DIALOG_DATA,
          useValue: null,
        },
      ],
    };
  }
}
