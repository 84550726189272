import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardStripHtml',
})
export class DashboardStripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    return value?.replace(/<.*?>/g, '');
  }
}
