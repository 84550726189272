import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../../app.routes';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state';
import { enableRegistration } from '../../../state/user/user.actions';

@Component({
  selector: 'dashboard-register-info',
  templateUrl: './register-info.component.html',
  styleUrls: ['./register-info.component.scss'],
})
export class RegisterInfoComponent implements OnInit {
  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {}

  back(): void {
    this.store.dispatch(enableRegistration());
    this.router.navigate([APP_ROUTES.LOGIN]);
  }
}
