import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { AppState } from '../../../state';
import { getToken } from '../../token-helper';
import { STUDIES_ROUTES } from '../../../dashboard/studies/studies.routes';

@Injectable({
  providedIn: 'root',
})
export class NonAuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (getToken()) {
      this.router.navigate([STUDIES_ROUTES.ROOT]);
      return false;
    }

    return true;
  }

  constructor(private store: Store<AppState>, private router: Router) {}
}
