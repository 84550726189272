import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

export const TEN_DIGITS_NO_LEADING_ZEROS_PATTERN = '^((?!(0))[a-z0-9]{10})$';
export const EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach((key) => {
      if (formGroup.controls[key].value) {
        checked++;
      }
    });

    return checked < minRequired ? { requireCheckboxesToBeChecked: true } : null;
  };
}

export function passwordsMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.get('password').value;
  const repeatPassword = control.get('repeatPassword').value;

  return password !== repeatPassword ? { noMatch: true } : null;
}

export function removeWhitespaces(control: AbstractControl): { [key: string]: boolean } | null {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
    return { required: true };
  }
  return null;
}

export function cannotContainSpace(control: AbstractControl): { [key: string]: boolean } | null {
  if (control && control.value && (control.value as string).indexOf(' ') >= 0) {
    return { cannotContainSpace: true };
  }
  return null;
}

export function customMinLength(length: number): ValidatorFn {
  return (control: FormControl) => {
    if (!control.value || (typeof control.value === 'string' && control.value.trim().length < length)) {
      return {
        minlength: true,
        requiredLength: length,
      };
    }

    return null;
  };
}

export function dateLessThan(dateField1: string, dateField2: string): ValidatorFn {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    let date1 = c.get(dateField1).value;
    let date2 = c.get(dateField2).value;

    if (typeof date1 === 'string') {
      date1 = moment(date1);
    }

    if (typeof date2 === 'string') {
      date2 = moment(date2);
    }

    if (date1 !== null && date2 !== null && (date1.isAfter(date2) || date1.isSame(date2))) {
      return { endDateInvalid: true };
    }
    if (date1 > moment()) {
      return { startDateInvalid: true };
    }
    return null;
  };
}
