import { Pipe, PipeTransform } from '@angular/core';
import { StudyTranslation } from '../../../models/study-translation';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'dashboardTranslate',
})
export class DashboardTranslatePipe implements PipeTransform {
  transform(translations: StudyTranslation[], identifier: string, selectedLanguageCode: string): string {
    const defaultLang = environment.defaultLang;

    if (!translations) {
      return null;
    }

    if (!selectedLanguageCode) {
      selectedLanguageCode = defaultLang;
    }

    for (const translation of translations) {
      if (translation.language === selectedLanguageCode && translation.identifier === identifier) {
        return translation.translation;
      }
    }
    return null;
  }
}
