export const environment = {
  production: true,
  environmentName: 'production',
  appUrl: 'https://app.tech4people-apps.bms.utwente.nl',
  apiUrl: 'https://api.tech4people-apps.bms.utwente.nl',
  publicUrl: 'https://api.tech4people-apps.bms.utwente.nl/storage/',
  ccFeedbackUrl: 'http://cc-feedback.tech4people-apps.bms.utwente.nl',
  careerUrl: 'https://cc.tech4people-apps.bms.utwente.nl',
  privacyUrl: 'https://www.utwente.nl/en/about-our-website/',
  version: '/v1',
  whitelistedDomains: ['localhost:8000', 'api.tech4people-apps.bms.utwente.nl', 'itunes.apple.com'],
  disallowedRoutes: [ ],
  tokenExpirityTime: 10800, // time in seconds
  defaultLang: 'en',
  mqtt: {
    credentials: {
      username: 'bmslab',
      password: 'bmslab',
    },
    host: 'linux752.bms.utwente.nl',
    port: 443,
    path: '/mqtt',
  },
};
