import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { AppState } from 'src/app/state';
import { passwordsMatchValidator, removeWhitespaces } from 'src/app/core/custom-form-validators';
import { PwdResetRequest } from 'src/app/models/pwd-reset-request';
import { selectPwdResetToken } from 'src/app/state/user/user.selectors';
import { pwdReset } from 'src/app/state/user/user.actions';

@Component({
  selector: 'dashboard-pwd-reset',
  templateUrl: './pwd-reset.component.html',
  styleUrls: ['./pwd-reset.component.scss'],
})
export class PwdResetComponent implements OnInit {
  pwdResetForm: FormGroup;
  pwdResetToken$: Observable<string>;

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.pwdResetForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32), removeWhitespaces]],
        repeatPassword: [
          '',
          [Validators.required, Validators.minLength(8), Validators.maxLength(32), removeWhitespaces],
        ],
      },
      { validators: passwordsMatchValidator }
    );
    this.pwdResetToken$ = this.store.select(selectPwdResetToken);
  }

  get password(): AbstractControl {
    return this.pwdResetForm.get('password');
  }

  get repeatPassword(): AbstractControl {
    return this.pwdResetForm.get('repeatPassword');
  }

  buildPwdResetRequest(): PwdResetRequest {
    const pwdResetFormValue = this.pwdResetForm.value;
    const pwdResetObject: PwdResetRequest = {
      password: pwdResetFormValue.password,
      password_confirmation: pwdResetFormValue.repeatPassword,
    };
    this.pwdResetToken$.subscribe((data) => (pwdResetObject.token = data));
    return pwdResetObject;
  }

  pwdReset(): void {
    this.pwdResetForm.markAllAsTouched();

    if (this.pwdResetForm.invalid) {
      return;
    }

    const pwdResetRequest = this.buildPwdResetRequest();
    this.store.dispatch(pwdReset({ pwdResetRequest }));
  }
}
