import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { LANGUAGES } from '../../../core/dashboard-constants';
import * as studyActions from './study.actions';
import { Study } from '../../../models/study';
import { Language } from '../../../models/language';
import { sortByDate } from '../../studies/helpers/date-helper';
import { transformLanguageCodesToLanguageEntities } from '../../../core/languages-helper';

export interface StudyState extends EntityState<Study> {
  loadingStudies: boolean;
  studiesLoaded: boolean;
  selectedStudy: Study;
  loadingSelectedStudy: boolean;
  selectedStudyLoaded: boolean;
  isSideNavOpen: boolean;
  sideNavType: string;
  supportedLanguages: Language[];
  selectedLanguage: Language;
  uploadedComplete: boolean;
}

export function sortByCreateDate(study1: Study, study2: Study): number {
  return sortByDate(study1?.createdAt, study2?.createdAt);
}

export const studyAdapter: EntityAdapter<Study> = createEntityAdapter<Study>({
  sortComparer: sortByCreateDate,
});

export const initialStudyState: StudyState = studyAdapter.getInitialState({
  loadingStudies: false,
  studiesLoaded: false,
  loadingSelectedStudy: false,
  selectedStudyLoaded: false,
  selectedStudy: null,
  isSideNavOpen: false,
  uploadedComplete: false,
  sideNavType: '',
  supportedLanguages: [LANGUAGES[0]],
  selectedLanguage: LANGUAGES[0],
});

export const reducer = createReducer(
  initialStudyState,
  on(studyActions.getStudies, (state) => {
    return {
      ...state,
      loadingStudies: true,
      studiesLoaded: false,
      isSideNavOpen: false,
      selectedStudy: null,
    };
  }),
  on(studyActions.getStudiesForOverview, (state) => {
    return {
      ...state,
      loadingStudies: true,
      studiesLoaded: false,
      isSideNavOpen: false,
      selectedStudy: null,
    };
  }),
  on(studyActions.getStudiesSuccess, (state, { studies }) => {
    return studyAdapter.setAll(studies, {
      ...state,
      loadingStudies: false,
      studiesLoaded: true,
      supportedLanguages: [LANGUAGES[0]],
      selectedLanguage: LANGUAGES[0],
    });
  }),
  on(studyActions.getStudiesError, (state) => {
    return {
      ...state,
      loadingStudies: false,
      studiesLoaded: false,
    };
  }),
  on(studyActions.openSideNav, (state, { navType }) => {
    return {
      ...state,
      isSideNavOpen: true,
      sideNavType: navType,
    };
  }),
  on(studyActions.addStudySupportedLanguages, (state, { supportedLanguages }) => {
    return {
      ...state,
      supportedLanguages,
    };
  }),
  on(studyActions.setSelectedLanguage, (state, { selectedLanguage }) => {
    return {
      ...state,
      selectedLanguage,
    };
  }),
  on(studyActions.createStudySuccess, (state, { newStudy }) => {
    return studyAdapter.addOne(newStudy, {
      ...state,
      selectedStudy: newStudy,
    });
  }),
  on(studyActions.updateStudySuccess, (state, { updatedStudy }) => {
    return studyAdapter.updateOne(
      { id: updatedStudy.id, changes: updatedStudy },
      {
        ...state,
        selectedStudy: updatedStudy,
      }
    );
  }),
  on(studyActions.selectStudy, (state) => {
    return {
      ...state,
      loadingSelectedStudy: true,
      selectedStudyLoaded: false,
    };
  }),
  on(studyActions.selectStudySuccess, (state, { selectedStudy }) => {
    return {
      ...state,
      loadingSelectedStudy: false,
      selectedStudyLoaded: true,
      selectedStudy,
      selectedLanguage: transformLanguageCodesToLanguageEntities(selectedStudy.languages)[0],
    };
  }),
  on(studyActions.selectStudyError, (state) => {
    return {
      ...state,
      loadingSelectedStudy: false,
      selectedStudyLoaded: false,
    };
  }),
  on(studyActions.deleteStudySuccess, (state, { deletedStudy }) => {
    return studyAdapter.removeOne(deletedStudy.id, {
      ...state,
      selectedStudy: null,
    });
  }),
  on(studyActions.duplicateStudySuccess, (state, { duplicatedStudy }) => {
    return studyAdapter.addOne(duplicatedStudy, {
      ...state,
    });
  }),
  on(studyActions.setSelectedStudy, (state, { selectedStudy }) => {
    return {
      ...state,
      selectedStudy,
    };
  }),
  on(studyActions.uploadStudyImageSuccess, (state, { uploadedComplete }) => {
    return {
      ...state,
      uploadedComplete
    };
  })
)
export function studyReducer(state: StudyState | undefined, action: Action) {
  return reducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = studyAdapter.getSelectors();
