import { environment } from '../../environments/environment';

const TWENTE_SSO_LOGIN_ROUTE = '/saml2/utwente/login';
const TWENTE_SSO_LOGOUT_ROUTE = '/saml2/utwente/logout?nameId=';

const M_NUMBER_REGEX = new RegExp(/^[mx]\d{7}$/);
const TWENTE_EMAIL_DOMAIN = '@utwente.nl';
const TWENTE_STUDENT_EMAIL_DOMAIN = '@student.utwente.nl';
const UT_ACCOUNT_EXCEPTIONS = [
  'bmslab@utwente.nl',
  'm.nieuwenhuis@utwente.nl',
  'g.h.pastoor@student.utwente.nl',
  'n.goossen@utwente.nl',
];

export function checkIsUtAccount(email: string): boolean {
  return (
    M_NUMBER_REGEX.test(email) ||
    ((email.includes(TWENTE_EMAIL_DOMAIN) || email.includes(TWENTE_STUDENT_EMAIL_DOMAIN)) &&
      !UT_ACCOUNT_EXCEPTIONS.includes(email))
  );
}

export function ssoLogin(): void {
  window.location.href = `${environment.apiUrl}${TWENTE_SSO_LOGIN_ROUTE}`;
}

export function ssoLogout(userEmail: string): void {
  window.location.href = `${environment.apiUrl}${TWENTE_SSO_LOGOUT_ROUTE}${userEmail}`;
}
