<div class="schedule-form">
  <div class="dialog-header-container">
    <div></div>
    <div>
      <h2>{{moduleTitle}}</h2>
    </div>
    <button class="close close-more-popup" (click)="cancel()">&times;</button>
  </div>

  <div class="date-range">
    <div class="start-date-input">
      <mat-form-field *ngIf="!isRange">
        <input matInput
               type="date"
               placeholder="Date"
               [(ngModel)]="date">
      </mat-form-field>
      <mat-form-field *ngIf="isRange">
        <input matInput
               type="date"
               placeholder="Start Date"
               [(ngModel)]="startDate"
               [max]="minusOneDay(endDate)">
      </mat-form-field>
    </div>
    <div class="toggle-weekdays">
      <div class="date-toggle-container" *ngIf="showRange">
        <span class="toggle-label">Single Date</span>
        <mat-slide-toggle [(ngModel)]="isRange" (ngModelChange)="changeRangeView()"></mat-slide-toggle>
        <span class="toggle-label">Range of Dates</span>
      </div>
      <div *ngIf="isRange && !isRelativeType"
           class="week-toggle-container"
           matTooltip="Please select days in the week that you would like your module to appear, based on the start and end date."
           matTooltipPosition="above">
        <div class="days-of-week">
          <button *ngFor="let day of daysOfWeek"
                  [class.selected]="selectedDays.includes(day.value)"
                  (click)="toggleDay(day.value)">
            {{ day.shortName }}
          </button>
        </div>
      </div>
      <div *ngIf="isRange && isRelativeType"
           class="week-toggle-container"
           matTooltip="Please select amount of days after the participant has started that you would like your module to appear, based on the start and end date."
           matTooltipPosition="above">
        <div class="days-of-week">
          <button *ngFor="let day of daysOfWeek"
                  [class.selected]="selectedDays.includes(day.value)"
                  (click)="toggleDay(day.value)">
            {{ day.value }}
          </button>
        </div>
      </div>
    </div>
    <div class="end-date-input">
      <mat-form-field *ngIf="isRange">
        <input matInput
               type="date"
               placeholder="End date"
               [(ngModel)]="endDate"
               [min]="plusOneDay(startDate)">
      </mat-form-field>
    </div>
  </div>
  <div>
    <div *ngIf="hasNoDate() && savedUpdateButtonClicked && !isRange"
         class="error">
      Please select a Date!
    </div>
    <div *ngIf="hasNoStartDate() && savedUpdateButtonClicked && isRange"
         class="error">
      Please select a Start Date!
    </div>
    <div *ngIf="hasNoEndDate() && savedUpdateButtonClicked && isRange"
         class="error">
      Please select a End Date!
    </div>
    <div *ngIf="hasNoWeekDays() && savedUpdateButtonClicked && isRange"
         class="error">
      Please select one or more Week Days!
    </div>
    <div *ngIf="!checkDateDifference() && savedUpdateButtonClicked && isRange"
         class="error">
      Please select a End Date that is at least 1 day later than the Start Day!
    </div>
  </div>
  <div class="time-range">
    <div>
      <mat-form-field>
        <input matInput
               type="time"
               placeholder="Start time"
               [(ngModel)]="startTime">
      </mat-form-field>
    </div>
    <div class="end-date-input">
      <mat-form-field>
        <input matInput
               type="time"
               placeholder="End time"
               [(ngModel)]="endTime">
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="!checkTimeDifference()"
       class="error">
    {{this.timeRangeError}}
  </div>

  <div class="checkbox-group">
    <mat-radio-group name="answer-type"
                     class="radio-group"
                     [(ngModel)]="selectedOption"
                     (ngModelChange)="onOptionChange()">
      <mat-radio-button class="one-time"
                        value="one-time">
        <div class="radio-content">
          <span>Answer one time</span>
          <mat-icon class="help-icon"
                    matTooltipClass="tooltip"
                    matTooltip="Answer one time: The module will be open in the specified start time and the participant will be able to answer once before the end time. After the participant answers the module, the module will not open again."
                    matTooltipPosition="above">
            help_outline
          </mat-icon>
        </div>
      </mat-radio-button>
      <div>
        <div class="radio-content">
          <mat-radio-button value="iterative">Iteratively between start and end time,</mat-radio-button>
          <mat-form-field class="input-number">
            <input matInput
                   #iterativeAmountInput
                   type="number"
                   [disabled]="selectedOption !== 'iterative'"
                   min="1"
                   max="{{maxTimes()}}"
                   [(ngModel)]="iterativeAmount">
          </mat-form-field>
          times
          <mat-icon class="help-icon"
                    matTooltipClass="tooltip"
                    matTooltip="Iteratively: The modules will be sent the specified number of times to the participants within the selected period. TIIM separates the duration of the period in equal parts and releases one iteration at the start at each subperiod. At the end of each sub-period, the module iteration will be closed.

Example:  A module is set to be sent 2 times iteratively between 9:00 and 11:00. The participants will receive the first iteration at 09:00 and will be able to answer until 10:00 and the second iteration at 10:00 and will be able to answer until 11:00."
                    matTooltipPosition="above">
            help_outline
          </mat-icon>
        </div>
        <div *ngIf="!checkIterativeOption()"
             class="error">
          {{optionIterativeErrorMessage}}
        </div>
      </div>
      <div>
        <div class="radio-content">
          <mat-radio-button value="random">Randomly between start and end time,</mat-radio-button>
          <mat-form-field class="input-number">
            <input matInput
                   #randomAmountInput
                   type="number"
                   [disabled]="selectedOption !== 'random'"
                   min="1"
                   max="{{maxTimes()}}"
                   [(ngModel)]="randomAmount">
          </mat-form-field>
          times
          <mat-icon class="help-icon"
                    matTooltipClass="tooltip"
                    matTooltip="Randomly: TIIM separates the period chosen into equal parts, depending on how many instances the module is set to be sent. Within the subperiods, the modules will be sent randomly. The participants will have 15 minutes to answer and send the module.

Example: A module is set to be sent 2 times randomly between 9:00 and 11:00 a participant may receive the module at 9:10 for the first time and at 10:30 for the second time."
                    matTooltipPosition="above">
            help_outline
          </mat-icon>
        </div>
        <div *ngIf="!checkRandomOption()"
             class="error">
          {{optionRandomErrorMessage}}
        </div>
      </div>
    </mat-radio-group>
  </div>

  <div class="button-group">
    <button class="cancel-button"
            *ngIf="!id"
            (click)="cancel()">
      Cancel
    </button>
    <button class="cancel-button"
            *ngIf="id"
            (click)="removeEvent()">
      Remove
    </button>
    <button class="save-button"
            *ngIf="!id"
            (click)="saveEvent()">
      Save
    </button>
    <button class="save-button"
            *ngIf="id"
            (click)="updateEvent()">
      Update
    </button>
  </div>
</div>
