import {Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {Times} from "../../../../models/trigger";

@Component({
  selector: 'dashboard-event-planner-dialog',
  templateUrl: './event-planner-dialog.component.html',
  styleUrls: ['./event-planner-dialog.component.scss'],
})
export class EventPlannerDialogComponent implements OnInit {
  @ViewChild('randomAmountInput') randomAmountInput: ElementRef;
  @ViewChild('iterativeAmountInput') iterativeAmountInput: ElementRef;
  startDate: string;
  tempStartDate: string;
  endDate: string;
  id: number
  moduleTitle: string;
  date: string;
  daysOffset: number;
  startTime: string;
  endTime: string;
  selectedOption = 'one-time';
  amount: number;
  randomAmount: number;
  iterativeAmount: number;
  optionRandomErrorMessage: string;
  optionIterativeErrorMessage: string;
  savedUpdateButtonClicked = false;
  setTimes: Times[] = [];
  isRange = false;
  showRange = true;
  timeRangeError = '';

  isRelativeType = false;
  participantStartDate = '';

  selectedDays: number[] = [];
  calculatedDates: string[] = [];
  daysOfWeek = [
    {value: 0, shortName: 'Su'},
    {value: 1, shortName: 'Mo'},
    {value: 2, shortName: 'Tu'},
    {value: 3, shortName: 'We'},
    {value: 4, shortName: 'Th'},
    {value: 5, shortName: 'Fr'},
    {value: 6, shortName: 'Sa'},
  ];

  constructor(public dialogRef: MatDialogRef<EventPlannerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.moduleTitle = data.moduleTitle;
    if (data.id) {
      this.isRelativeType = data.relativeType;
      this.participantStartDate = data.participantStartDay;
      this.showRange = false;
      this.id = data.id
      this.date = this.formatDateToYYYYMMDD(data.date, data.daysOffset);
      this.daysOffset = data.daysOffset;
      this.startTime = data.start;
      this.tempStartDate = this.startDate;
      this.endTime = data.end;
      this.selectedOption = data.optionType;
      this.setTimes = data.setTimes;
      if (data.optionType === 'random') {
        this.randomAmount = data.amount;
      } else if (data.optionType === 'iterative') {
        this.iterativeAmount = data.amount;
      }
    } else {
      const dateObject = new Date(data.date);

      this.isRelativeType = data.relativeType;
      this.participantStartDate = data.participantStartDay;

      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();

      this.date = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

      if (data.view === 'week') {
        this.startTime = this.toPaddedTimeString(dateObject.getHours(), dateObject.getMinutes());
        const endTimeObject = new Date(dateObject.getTime() + 30 * 60000);
        this.endTime = this.toPaddedTimeString(endTimeObject.getHours(), endTimeObject.getMinutes());
      }
    }

    this.startDate = this.date;
    this.tempStartDate = this.startDate;
  }

  ngOnInit(): void {

  }

  toPaddedTimeString(hours: number, minutes: number): string {
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    return `${paddedHours}:${paddedMinutes}`;
  }

  toggleDay(dayValue: number) {
    const index = this.selectedDays.indexOf(dayValue);
    if (index > -1) {
      this.selectedDays.splice(index, 1);
    } else {
      this.selectedDays.push(dayValue);
    }
  }

  changeRangeView() {
    if (this.isRange && this.isRelativeType) {
      this.startDate = this.participantStartDate;
    } else {
      this.startDate = this.tempStartDate;
    }
  }

  calculateDates() {
    if (!this.isRange) {
      return;
    }

    if (!this.startDate || !this.endDate || this.selectedDays.length === 0) {
      return;
    }

    this.calculatedDates = [];
    const currentDate = new Date(this.startDate);
    const endDateDate = new Date(this.endDate);

    if (this.isRelativeType) {
      const tempSelectedDays = [];
      this.selectedDays.forEach(offset => {
        const date = new Date(this.offsetToDate(offset));
        tempSelectedDays.push(date.getDay());
      });

      this.selectedDays = tempSelectedDays;
    }

    while (currentDate <= endDateDate) {
      if (this.selectedDays.includes(currentDate.getDay())) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();

        this.calculatedDates.push(`${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  plusOneDay(day: string): string {
    if (day) {
      const tomorrow = new Date(day);
      tomorrow.setDate(tomorrow.getDate() + 1);

      return tomorrow.toISOString().split('T')[0];
    }
  }

  minusOneDay(day: string): string {
    if (day) {
      const tomorrow = new Date(day);
      tomorrow.setDate(tomorrow.getDate() - 1);
      return tomorrow.toISOString().split('T')[0];
    }
  }

  formatDateToYYYYMMDD(dateString: string, offset: number): string {
    if (this.isRelativeType) {
      return this.offsetToDate(offset);
    } else {
      const dateParts = dateString.split('-').map(part => part.padStart(2, '0'));
      return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  removeEvent() {
    const removeData = {
      id: this.id,
      remove: true
    }
    this.dialogRef.close(removeData);
  }

  saveEvent() {
    this.calculateDates();
    this.savedUpdateButtonClicked = true;
    if (this.allChecksPassed()) {
      if (this.isRange) {
        const returnData = {
          listOfEvents: []
        };
        this.calculatedDates.forEach(calculatedDate => {
          this.createTimings();
          const dataObject = {
            date: calculatedDate,
            daysOffset: this.dateToOffset(calculatedDate),
            start: this.startTime,
            end: this.endTime,
            selectedOption: this.selectedOption,
            amount: this.amount,
            setTimes: this.setTimes
          };
          returnData.listOfEvents.push(dataObject);
        });
        this.dialogRef.close(returnData);
      } else {
        this.createTimings();
        const returnData = {
          date: this.date,
          daysOffset: this.dateToOffset(this.date),
          start: this.startTime,
          end: this.endTime,
          selectedOption: this.selectedOption,
          amount: this.amount,
          setTimes: this.setTimes
        };
        this.dialogRef.close(returnData);
      }
    }
  }

  updateEvent() {
    this.savedUpdateButtonClicked = true;
    if (this.allChecksPassed()) {
      this.createTimings();
      const returnData = {
        id: this.id,
        date: this.date,
        daysOffset: this.dateToOffset(this.date),
        start: this.startTime,
        end: this.endTime,
        selectedOption: this.selectedOption,
        amount: this.amount,
        setTimes: this.setTimes
      };
      this.dialogRef.close(returnData);
    }
  }

  allChecksPassed(): boolean {
    if (this.hasNoDate() && !this.isRange) {
      return false;
    }

    if (this.hasNoStartDate() && this.isRange) {
      return false;
    }

    if (this.hasNoEndDate() && this.isRange) {
      return false;
    }

    if (this.hasNoWeekDays() && this.isRange) {
      return false;
    }

    if (!this.checkDateDifference() && this.isRange) {
      return false;
    }

    if (!this.checkTimeDifference()) {
      return false;
    }

    return this.checkSelectedOption();
  }

  hasNoDate(): boolean {
    return !this.date;
  }

  hasNoStartDate(): boolean {
    return !this.startDate;
  }

  hasNoEndDate(): boolean {
    return !this.endDate;
  }

  hasNoWeekDays(): boolean {
    return this.selectedDays.length === 0;
  }

  checkDateDifference(): boolean {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    const startDate = new Date(this.startDate);
    const endDate = new Date(this.endDate);

    const timeDiff = endDate.getTime() - startDate.getTime();

    return timeDiff >= oneDayInMilliseconds;
  }

  checkTimeDifference(): boolean {
    if (this.startTime !== undefined && this.endTime !== undefined) {
      const [startHours, startMinutes] = this.startTime.split(':').map(Number);
      const [endHours, endMinutes] = this.endTime.split(':').map(Number);

      const startDate = new Date();
      startDate.setHours(startHours, startMinutes, 0, 0);

      const endDate = new Date();
      endDate.setHours(endHours, endMinutes, 0, 0);

      if (endDate < startDate) {
        this.timeRangeError = "End time cannot be before start time.";
        return false;
      }

      const difference = endDate.getTime() - startDate.getTime();

      if (difference < 1800000) {
        this.timeRangeError = "The time difference between the start and the end time should be at least 30 minutes!";
        return false;
      }

      this.timeRangeError = "";
      return true;
    } else {
      return false;
    }
  }

  checkSelectedOption(): boolean {
    switch (this.selectedOption) {
      case 'one-time':
        return true;
      case 'random':
        return this.checkRandomOption();
      case 'iterative':
        return this.checkIterativeOption();
    }
  }

  checkRandomOption(): boolean {
    this.amount = this.randomAmount;
    if (this.selectedOption === 'random') {
      if (!this.amount || this.amount < 1) {
        this.optionRandomErrorMessage = 'Please add a number for the amount of times!';
        return false;
      } else if (this.amount > this.maxTimes()) {
        if (this.maxTimes() < 0) {
          this.optionRandomErrorMessage = 'Please fix the start and end time.';
        } else {
          this.optionRandomErrorMessage = 'Please add a number that is lower or equal to the max amount: ' + this.maxTimes();
        }
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkIterativeOption(): boolean {
    this.amount = this.iterativeAmount;
    if (this.selectedOption === 'iterative') {
      if (!this.amount || this.amount < 1) {
        this.optionIterativeErrorMessage = 'Please add a number for the amount of times!';
        return false;
      } else if (this.amount > this.maxTimes()) {
        if (this.maxTimes() < 0) {
          this.optionIterativeErrorMessage = 'Please fix the start and end time.';
        } else {
          this.optionIterativeErrorMessage = 'Please add a number that is lower or equal to the max amount: ' + this.maxTimes();
        }
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  maxTimes(): number {
    if (this.startTime && this.endTime) {
      const [startHours, startMinutes] = this.startTime.split(':').map(Number);
      const [endHours, endMinutes] = this.endTime.split(':').map(Number);

      const startDate = new Date();
      startDate.setHours(startHours, startMinutes, 0, 0);

      const endDate = new Date();
      endDate.setHours(endHours, endMinutes, 0, 0);

      const durationInMinutes = (endDate.getTime() - startDate.getTime()) / (1000 * 60);
      return Math.floor(durationInMinutes / 15);
    }
  }

  createTimings() {
    switch (this.selectedOption) {
      case 'one-time':
        this.amount = 1;
        this.createOneTimeTiming()
        break;
      case 'random':
        this.createRandomTimings();
        break;
      case 'iterative':
        this.createIterativeTimings();
        break;
    }
  }

  createOneTimeTiming() {
    this.setTimes = [];
    this.setTimes.push({
      startTime: this.startTime,
      endTime: this.endTime,
    })
  }

  createRandomTimings() {
    const start = this.convertTimeToMinutes(this.startTime);
    const end = this.convertTimeToMinutes(this.endTime);
    const totalDuration = end - start;
    const sectionDuration = Math.floor(totalDuration / this.amount);

    this.setTimes = [];
    for (let i = 0; i < this.amount; i++) {
      const sectionStart = start + i * sectionDuration;
      const sectionEnd = i === this.amount - 1 ? end : sectionStart + sectionDuration;

      const randomStart = this.getRandomTime(sectionStart, sectionEnd - 15);
      const randomEnd = randomStart + 15;

      this.setTimes.push({
        startTime: this.convertMinutesToTime(randomStart),
        endTime: this.convertMinutesToTime(randomEnd),
      });
    }
  }

  createIterativeTimings() {
    const start = this.convertTimeToMinutes(this.startTime);
    const end = this.convertTimeToMinutes(this.endTime);
    const totalDuration = end - start;
    const sectionDuration = Math.floor(totalDuration / this.amount);

    this.setTimes = [];
    let currentTime = start;

    for (let i = 0; i < this.amount; i++) {
      let sectionEndTime = currentTime + sectionDuration;
      if (i === this.amount - 1 && sectionEndTime > end) {
        sectionEndTime = end;
      }

      this.setTimes.push({
        startTime: this.convertMinutesToTime(currentTime),
        endTime: this.convertMinutesToTime(sectionEndTime),
      });

      currentTime = sectionEndTime;
    }
  }

  convertTimeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  convertMinutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  getRandomTime(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  onOptionChange(): void {
    if (this.selectedOption !== 'random') {
      this.amount = null;
      this.randomAmountInput.nativeElement.value = '';
    }

    if (this.selectedOption !== 'iterative') {
      this.amount = null;
      this.iterativeAmountInput.nativeElement.value = '';
    }
  }

  offsetToDate(offset: number): string {
    const date = new Date(this.participantStartDate);
    date.setDate(date.getDate() + offset);
    return date.toISOString().split('T')[0];
  }

  dateToOffset(date: string): number {
    const date1 = new Date(this.participantStartDate);
    const date2 = new Date(date);

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

}
