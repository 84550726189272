<div class="dashboard-register-fail">
  <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
    <h1 class="dashboard-register-fail__title">
      {{ errorMessage }}
    </h1>
    <section class="dashboard-register-fail__description">
      An error occured. Either your invitation URL is not correct or your invitation has expired. Please contact the BMS Lab and they will help you.
    </section>
    <section class="dashboard-register-fail__description">
      <strong>THE BMS LAB</strong><br />
      University of Twente<br />
      Faculty of Behavioural, Management and Social Sciences (BMS)<br />
      Cubicus B204<br />
      Phone: 053-4892524<br />
      E-mail address: dashboard-bms@utwente.nl
    </section>
    <button mat-raised-button color="accent" id="register-fail-btn-back-to-login" fxFlexAlign="end" (click)="back()">
      {{ 'Back to login page' | uppercase }}
      
    </button>
  </div>
</div>
