import { createAction, props } from '@ngrx/store';
import { AuthErrorResponse } from '../../../models/auth-error-response';
import { Study } from '../../../models/study';
import { Language } from '../../../models/language';
import { HttpErrorResponse } from '@angular/common/http';

export const getStudies = createAction('[Study] Get Studies');

export const getStudiesForOverview = createAction('[Study] Get Overview Studies');

export const getStudiesSuccess = createAction('[Study] Get Studies Success', props<{ studies: Study[] }>());

export const getStudiesError = createAction('[Study] Get Studies Error', props<{ err: AuthErrorResponse }>());

export const openCreateStudyPageWithDetails = createAction(
  '[Study] Open Create Study Page With Details',
  props<{ studyType: string }>()
);

export const openSideNav = createAction('[Study] Open Side Navigation Bar', props<{ navType: string }>());

export const addStudySupportedLanguages = createAction(
  '[Study] Add Study Supported Languages',
  props<{ supportedLanguages: Language[] }>()
);

export const setSelectedLanguage = createAction(
  '[Study] Set Selected Language',
  props<{ selectedLanguage: Language }>()
);

export const createStudy = createAction('[Study] Create Study', props<{ study: Study, uuid?: string }>());

export const createStudySuccess = createAction('[Study] Create Study Success', props<{ newStudy: Study }>());

export const createStudyError = createAction('[Study] Create Study Error', props<{ err: HttpErrorResponse }>());

export const selectStudy = createAction('[Study] Select Study', props<{ studyId: number }>());

export const selectStudySuccess = createAction('[Study] Select Study Success', props<{ selectedStudy: Study }>());

export const selectStudyError = createAction('[Study] Select Study Error', props<{ err: HttpErrorResponse }>());

export const updateStudySuccess = createAction('[Study] Update Study Success', props<{ updatedStudy: Study }>());

export const updateStudyError = createAction('[Study] Update Study Error', props<{ err: HttpErrorResponse }>());

export const updateStudy = createAction('[Study] Update Study', props<{ study: Study, skipFlash?: boolean }>());

export const deleteStudy = createAction('[Study] Delete Study', props<{ study: Study }>());

export const deleteStudySuccess = createAction('[Study] Delete Study Success', props<{ deletedStudy: Study }>());

export const deleteStudyError = createAction('[Study] Delete Study Error', props<{ err: HttpErrorResponse }>());

export const duplicateStudy = createAction('[Study] Duplicate Study', props<{ study: Study }>());

export const duplicateStudySuccess = createAction(
  '[Study] Duplicate Study Success',
  props<{ duplicatedStudy: Study }>()
);

export const duplicateStudyError = createAction('[Study] Duplicate Study Error', props<{ err: HttpErrorResponse }>());

export const noopAction = createAction('[Study] No Operations Action');

export const setSelectedStudy = createAction('[Study] Set Selected Study', props<{ selectedStudy: Study }>());
export const uploadStudyImage = createAction('[Study] Add Image To New Study', props<{ newStudy: Study, uuid: string }>());
export const uploadStudyImageSuccess = createAction('[Study] Add Image To New Study Success', props<{ uploadedComplete: boolean }>());
