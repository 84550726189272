import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardStripDynamicValues',
})
export class DashboardStripDynamicValuesPipe implements PipeTransform {
  transform(value: string): any {
    return value?.replace(/\[.*?\]/g, '');
  }
}
