import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../../state';
import { SectionType } from '../../../models/section-type';
import { selectUser } from '../../../state/user/user.selectors';
import { STUDIES_ROUTES } from '../../../dashboard/studies/studies.routes';

@Injectable({
  providedIn: 'root',
})
export class PrivilegesGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const section = next?.data?.section || '';
    const selectedStudyId = +next?.params?.id || 0;

    return this.store.pipe(
      select(selectUser),
      map((user) => {
        if (user?.superuser) {
          return true;
        }

        if (
          section === STUDIES_ROUTES.CREATE_STUDY &&
          next?.queryParams?.hasOwnProperty('type') &&
          user?.createStudies
        ) {
          return true;
        }

        for (const privilege of user.privileges) {
          if (
            privilege.studyId === selectedStudyId &&
            (privilege.section === section || privilege.section === SectionType.ALL)
          ) {
            return true;
          }
        }

        this.router.navigate([STUDIES_ROUTES.ROOT]);
        return false;
      })
    );
  }

  constructor(private store: Store<AppState>, private router: Router) {}
}
