import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { AppState } from '../../../state';
import { Role } from '../../../models/role';
import { selectUser } from '../../../state/user/user.selectors';

@Directive({
  selector: '[dashboardHasRole]',
})
export class HasRoleDirective implements OnDestroy {
  roleSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>
  ) {}

  @Input() set dashboardHasRole(role: Role) {
    this.roleSubscription = this.store.select(selectUser).subscribe((user) => {
      if (!user) {
        this.hideView();
        return;
      }

      if (user?.superuser) {
        this.showView();
        return;
      }

      if (role === Role.ADMIN) {
        user.superuser ? this.showView() : this.hideView();
        return;
      }

      if (role === Role.CREATOR) {
        user.createStudies ? this.showView() : this.hideView();
        return;
      }
    });
  }

  private showView() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hideView() {
    this.viewContainer.clear();
  }

  ngOnDestroy() {
    this.roleSubscription.unsubscribe();
  }
}
