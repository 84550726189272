export function sortByDate(date1: string, date2: string): number {
  const convertedDate1 = changeDateFormat(date1);
  const convertedDate2 = changeDateFormat(date2);

  return new Date(convertedDate2).getTime() - new Date(convertedDate1).getTime();
}

export function millisecondsToTime(duration: number): string {
  let minutes = Number((duration / (1000 * 60)) % 60) || '';
  let hours = Number((duration / (1000 * 60 * 60)) % 24) || '';

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00';

  return `${hours}:${minutes}`;
}

/**
 * Sign "-" is replaced with "/" because of known issues
 * with parsing of dates on Safari
 */
export function changeDateFormat(dateValue: string): string {
  return dateValue.toString().replace(/-/g, '/');
}
