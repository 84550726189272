export enum SectionType {
  STUDY_DETAILS = 'study-details',
  PARTICIPANTS = 'participants',
  RESEARCHERS = 'researchers',
  INTERVENTIONS = 'interventions',
  MODULES = 'modules',
  REPORTS = 'reports',
  METRICS = 'metrics',
  ALL = 'all',
}
