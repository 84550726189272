import { createSelector } from '@ngrx/store';
import * as fromStudies from './study.reducer';
import { selectDashboardState } from '../dashboard.selectors';

export const selectStudyState = createSelector(selectDashboardState, (dashboard) => dashboard?.study);

export const selectIsLoadingStudies = createSelector(selectStudyState, (studyState) => studyState?.loadingStudies);

export const selectStudiesLoaded = createSelector(selectStudyState, (studyState) => studyState?.studiesLoaded);

export const selectStudies = createSelector(selectStudyState, fromStudies.selectAll);

export const selectIsLoadingSelectedStudy = createSelector(
  selectStudyState,
  (studyState) => studyState.loadingSelectedStudy
);

export const selectSelectedStudyLoaded = createSelector(
  selectStudyState,
  (studyState) => studyState.selectedStudyLoaded
);

export const selectSelectedStudy = createSelector(selectStudyState, (studyState) => studyState?.selectedStudy);

export const selectIsSideNavOpen = createSelector(selectStudyState, (studyState) => studyState?.isSideNavOpen);

export const selectSideNavType = createSelector(selectStudyState, (studyState) => studyState.sideNavType);

export const selectSupportedLanguages = createSelector(selectStudyState, (studyState) => studyState.supportedLanguages);

export const selectSelectedLanguage = createSelector(selectStudyState, (studyState) => studyState.selectedLanguage);
export const selectUploadStudyImage = createSelector(
  selectStudyState,
  (studyState) => studyState.uploadedComplete,
);
