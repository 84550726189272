import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardSnakeCase',
})
export class DashboardSnakeCasePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  keysToSnakeCase(object: any) {
    if (object === Object(object) && !Array.isArray(object) && typeof object !== 'function') {
      const result = {};
      Object.keys(object).forEach((k) => {
        result[this.transform(k)] = this.keysToSnakeCase(object[k]);
      });
      return result;
    } else if (Array.isArray(object)) {
      return object.map((i) => this.keysToSnakeCase(i));
    }
    return object;
  }
}
