import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthGuard } from './guards/auth/auth.guard';
import { NonAuthGuard } from './guards/non-auth/non-auth.guard';
import { DashboardSnakeCasePipe } from './pipes/dashboard-snake-case/dashboard-snake-case.pipe';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { DashboardCamelCasePipe } from './pipes/dashboard-camel-case/dashboard-camel-case.pipe';
import { DashboardTranslatePipe } from './pipes/dashboard-translate/dashboard-translate.pipe';
import { PrivilegesGuard } from './guards/privileges/privileges.guard';
import { FormControlDisabledDirective } from './directives/form-control-disabled/form-control-disabled.directive';
import { ConnectFormDirective } from './directives/connect-form/connect-form.directive';
import { DashboardSafePipe } from './pipes/dashboard-safe/dashboard-safe.pipe';
import { DashboardStripHtmlPipe } from './pipes/dashboard-strip-html/dashboard-strip-html.pipe';
import { DashboardStripDynamicValuesPipe } from './pipes/dashboard-strip-dynamic-values/dashboard-strip-dynamic-values.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthGuard,
    NonAuthGuard,
    PrivilegesGuard,
    DashboardSnakeCasePipe,
    DashboardCamelCasePipe,
    DashboardTranslatePipe,
    DashboardStripHtmlPipe,
  ],
  exports: [
    FormControlDisabledDirective,
    ConnectFormDirective,
    DashboardSnakeCasePipe,
    DashboardCamelCasePipe,
    DashboardTranslatePipe,
    DashboardSafePipe,
    HasRoleDirective,
    DashboardStripHtmlPipe,
    DashboardStripDynamicValuesPipe,
  ],
  declarations: [
    FormControlDisabledDirective,
    ConnectFormDirective,
    DashboardSnakeCasePipe,
    DashboardCamelCasePipe,
    DashboardTranslatePipe,
    DashboardSafePipe,
    HasRoleDirective,
    DashboardStripHtmlPipe,
    DashboardStripDynamicValuesPipe,
  ],
})
export class CoreModule {}
