import { createAction, props } from '@ngrx/store';

import { User } from '../../models/user';
import { AuthErrorResponse } from '../../models/auth-error-response';
import { Credentials } from '../../models/credentials';
import { RegisterRequest } from '../../models/register-request';
import { PwdResetRequest } from 'src/app/models/pwd-reset-request';
import { InvitedResearcher } from '../../models/invited-researcher';
import { InvitedRegisterRequest } from '../../models/invited-register-request';
import { AuthSetup } from '../../models/auth-setup';

export const login = createAction('[User] Login', props<{ credentials: Credentials }>());

export const loginTestEnv = createAction('[User] Login Test Environment', props<{ credentials: Credentials }>());

export const ssoLogin = createAction('[User] SSO Login', props<{ token: string }>());

export const logout = createAction('[User] Logout', props<{ email: string }>());

export const verifyLogin = createAction('[User] Verify Login', props<{ credentials: Credentials }>());

export const verifyLoginSuccess = createAction('[User] Verify Login Success', props<{ token: string; user?: User }>());

export const getProfileSuccess = createAction('[User] Get Profile Success', props<{ token: string; user?: User }>());

export const verifyLoginError = createAction('[User] Verify Login Error', props<{ err: AuthErrorResponse }>());

export const register = createAction('[User] Register', props<{ researcher: RegisterRequest }>());

export const registerSuccess = createAction('[User] Register Success', props<{ newUser: User }>());

export const registerError = createAction('[User] Register Error', props<{ err: AuthErrorResponse }>());

export const utAccountRegister = createAction('[User] UT Account Register', props<{ email: string }>());

export const getInvitedInfo = createAction(
  '[User] Get Invited Researcher Registration Information',
  props<{ code: string }>()
);

export const getInvitedInfoSuccess = createAction(
  '[User] Get Invited Researcher Registration Information Success',
  props<{ invitedResearcher: InvitedResearcher }>()
);

export const getInvitedInfoError = createAction(
  '[User] Get Invited Researcher Registration Information Error',
  props<{ err: AuthErrorResponse }>()
);

export const registerInvitedApplicant = createAction(
  '[User] Register Invited Applicant',
  props<{ invitedApplicant: InvitedRegisterRequest }>()
);

export const enableRegistration = createAction('[User] Enable Registration');

export const forgotPassword = createAction('[User] Forgot Password', props<{ email: string }>());

export const forgotPasswordSuccess = createAction('[User] Forgot Password Success');

export const forgotPasswordError = createAction('[User] Forgot Password Error', props<{ err: AuthErrorResponse }>());

export const pwdResetRouteNavigated = createAction(
  '[User] Password Reset Route Navigated',
  props<{ pwdResetToken: string }>()
);

export const pwdReset = createAction('[User] Password Reset', props<{ pwdResetRequest: PwdResetRequest }>());

export const pwdResetSuccess = createAction('[User] Password Reset Success');

export const pwdResetError = createAction('[User] Password Reset Error', props<{ err: AuthErrorResponse }>());

export const getProfile = createAction('[User] Get Profile');

export const getUserStudyTypes = createAction('[User] Get User Study Types', props<{ userId: number }>());

export const getUserStudyTypesSuccess = createAction(
  '[User] Get User Study Types Success',
  props<{ userStudyTypes: string[] }>()
);

export const getUserStudyTypesError = createAction(
  '[User] Get User Study Types Success',
  props<{ err: AuthErrorResponse }>()
);

export const saveAuthSetup = createAction('[User] Save Auth Setup', props<{ authSetup: AuthSetup }>());

export const noopAction = createAction('[User] No Operations Action');
