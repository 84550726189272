import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../../app.routes';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state';
import { enableRegistration } from '../../../state/user/user.actions';
import { selectInvitedErrorMessage } from '../../../state/user/user.selectors';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'dashboard-register-fail',
  templateUrl: './register-fail.component.html',
  styleUrls: ['./register-fail.component.scss'],
})
export class RegisterFailComponent implements OnInit {

  invitedErrorMessage$: Observable<string>;
  errorMessage = '';

  subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.invitedErrorMessage$ = this.store.select(selectInvitedErrorMessage);
    this.subscriptions.push(this.invitedErrorMessage$.subscribe((errorMessage) => (this.errorMessage = errorMessage)));
  }

  back(): void {
    this.store.dispatch(enableRegistration());
    this.router.navigate([APP_ROUTES.LOGIN]);
  }
}
